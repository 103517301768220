@font-face {
    font-family: 'fontello';
    src: url('../../public/fonts/fontello.eot');
    src: url('../../public/fonts/fontello.eot#iefix') format('embedded-opentype'),
         url('../../public/fonts/fontello.woff2') format('woff2'),
         url('../../public/fonts/fontello.woff') format('woff'),
         url('../../public/fonts/fontello.ttf') format('truetype'),
         url('../../public/fonts/fontello.svg') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  /* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
  /* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
  /*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'fontello';
      src: url('../font/fontello.svg#fontello') format('svg');
    }
  }
  */
   
   [class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
   
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
   
    text-align: center;
    /* opacity: .8; */
   
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
   
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
   
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
   
   
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
   
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
   
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  }
   
  .icon-023-tooth-7:before { content: '\e800'; } /* 'î €' */
  .icon-024-tooth-6:before { content: '\e801'; } /* 'î ' */
  .icon-025-tooth-5:before { content: '\e802'; } /* 'î ‚' */
  .icon-026-glass-of-water:before { content: '\e803'; } /* 'î ƒ' */
  .icon-027-water:before { content: '\e804'; } /* 'î „' */
  .icon-028-mouthwash:before { content: '\e805'; } /* 'î …' */
  .icon-029-medicine:before { content: '\e806'; } /* 'î †' */
  .icon-030-braces:before { content: '\e807'; } /* 'î ‡' */
  .icon-031-pills:before { content: '\e808'; } /* 'î ˆ' */
  .icon-032-anesthesia:before { content: '\e809'; } /* 'î ‰' */
  .icon-033-apple:before { content: '\e80a'; } /* 'î Š' */
  .icon-034-hook:before { content: '\e80b'; } /* 'î ‹' */
  .icon-035-lens:before { content: '\e80c'; } /* 'î Œ' */
  .icon-036-tooth-4:before { content: '\e80d'; } /* 'î ' */
  .icon-037-tooth-3:before { content: '\e80e'; } /* 'î Ž' */
  .icon-038-tooth-2:before { content: '\e80f'; } /* 'î ' */
  .icon-039-tooth-1:before { content: '\e810'; } /* 'î ' */
  .icon-040-tooth:before { content: '\e811'; } /* 'î ‘' */
  .icon-001-dentist:before { content: '\e812'; } /* 'î ’' */
  .icon-002-first-aid-kit:before { content: '\e813'; } /* 'î “' */
  .icon-003-tooth-13:before { content: '\e814'; } /* 'î ”' */
  .icon-004-tooth-12:before { content: '\e815'; } /* 'î •' */
  .icon-005-dental-floss-1:before { content: '\e816'; } /* 'î –' */
  .icon-006-tooth-pliers:before { content: '\e817'; } /* 'î —' */
  .icon-007-appointment:before { content: '\e818'; } /* 'î ˜' */
  .icon-008-tooth-11:before { content: '\e819'; } /* 'î ™' */
  .icon-009-braces-1:before { content: '\e81a'; } /* 'î š' */
  .icon-010-protection:before { content: '\e81b'; } /* 'î ›' */
  .icon-011-dental-care:before { content: '\e81c'; } /* 'î œ' */
  .icon-012-toothbrush-1:before { content: '\e81d'; } /* 'î ' */
  .icon-013-dental-floss:before { content: '\e81e'; } /* 'î ž' */
  .icon-014-clipboard:before { content: '\e81f'; } /* 'î Ÿ' */
  .icon-015-chair:before { content: '\e820'; } /* 'î  ' */
  .icon-016-tooth-10:before { content: '\e821'; } /* 'î ¡' */
  .icon-017-tooth-9:before { content: '\e822'; } /* 'î ¢' */
  .icon-018-mouth:before { content: '\e823'; } /* 'î £' */
  .icon-019-dental-drill:before { content: '\e824'; } /* 'î ¤' */
  .icon-020-toothbrush:before { content: '\e825'; } /* 'î ¥' */
  .icon-021-toothpaste:before { content: '\e826'; } /* 'î ¦' */
  .icon-022-tooth-8:before { content: '\e827'; } /* 'î §' */
  .icon-location42:before { content: '\e828'; } /* 'î ¨' */
  .icon-open210:before { content: '\e829'; } /* 'î ©' */
  .icon-phone351:before { content: '\e82a'; } /* 'î ª' */
  .icon-checked:before { content: '\e82b'; } /* 'î «' */