.floating-button {
	position:fixed;
	width:100px;
	height:100px;
	bottom:0px;
	right:0px;
	border-radius:50px;
	text-align:center;
    font-size:30px;
    z-index:100;
}